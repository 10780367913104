<template>
    <div class=" mt-44 sm:mt-96">
        <footer>
            <div class="mx-auto max-w-7xl overflow-hidden px-6 py-20 sm:py-24 lg:px-8 relative z-40 sm:mb-0 mb-16">
                <nav
                    class="-mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12"
                    aria-label="Footer">
                    <div
                        v-for="item in footerData"
                        :key="item.id"
                        class="pb-6">
                        <StrapiComponent :component="item" />
                    </div>
                </nav>
            </div>
        </footer>
        <FooterCircles />
    </div>
</template>

<script setup>

const {find} = useStrapi()

const footerData = ref(null)

const loading = ref(true)

await useAsyncData(() => find('menu-fusszeile', {populate: 'deep,3'}), {
    // this will not refetch if the key exists in the payload
    getCachedData: key => nuxtApp.payload.static[key] ?? nuxtApp.payload.data[key]
}).then(response => footerData.value = response.data?.value?.data?.attributes?.Menu ?? {}).finally(() => loading.value = false)
</script>

<style scoped>

</style>